const keys = {
    REACT_APP_FIREBASE_API_KEY: "AIzaSyDx-jGclBzQpAu2xGjxjTjEFzLgTYYlvF4",
    REACT_APP_STRIPE_PUBLIC_KEY: "pk_live_51NDFvIIYEgZdzuGXlbzYo8MdDzFqHyucxTvw7dAYMGrjHoLAtn7FDlJl3RDvHgxv4DfJw9Fj7GnIsmB0X8CQNtl600s7SnDTW8",
    REACT_APP_PROD_URL: "https://landlordassist.io",
    REACT_APP_RECAPTCHA_SITE_KEY: "6LdeW2omAAAAAFABlq9TQv4622IEBmZgMP9j77RI",
    REACT_APP_UPDATE_QUERY_LIMIT_URL: 'https://updatequerylimit-hur5h44zvq-uc.a.run.app/', //"http://127.0.0.1:5001/landlord-assistant/us-central1/updateQueryLimit",
    REACT_APP_BILLING_PORTAL_URL: 'https://createbillingportalsession-hur5h44zvq-uc.a.run.app/', //"http://127.0.0.1:5001/landlord-assistant/us-central1/createBillingPortalSession",
    REACT_APP_GET_CUSTOMER_URL: 'https://getcustomer-hur5h44zvq-uc.a.run.app', //"http://127.0.0.1:5001/landlord-assistant/us-central1/getCustomer", // 'https://updatequerylimit-hur5h44zvq-uc.a.run.app/'
    REACT_APP_GET_SUBSCRIPTION_URL: "https://getsubscription-hur5h44zvq-uc.a.run.app", // "http://127.0.0.1:5001/landlord-assistant/us-central1/getSubscription", // 'https://updatequerylimit-hur5h44zvq-uc.a.run.app/'
    REACT_APP_GET_PRODUCTS_URL: "https://getproducts-hur5h44zvq-uc.a.run.app", // "http://127.0.0.1:5001/landlord-assistant/us-central1/getProducts"
    REACT_APP_POST_TASK_DATA_URL: "https://posttaskdata-hur5h44zvq-uc.a.run.app", // "http://127.0.0.1:5001/landlord-assistant/us-central1/postTaskData",
}

export default keys
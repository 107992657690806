import React from 'react';
import { NewBannerStyled } from '../styles/Main';


function NewBanner() {

    return (
        <NewBannerStyled>
            <p>New</p>
        </NewBannerStyled>
    );
}

export default NewBanner;